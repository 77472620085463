import { debounce } from "lodash";
import { MutableRefObject, useLayoutEffect, useRef, useState } from "react";

export default function useClientRect<T extends HTMLElement>(): [
  DOMRect | undefined,
  MutableRefObject<T | null>,
] {
  const [rect, setRect] = useState<DOMRect | undefined>(undefined);
  const ref = useRef<T | null>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const measure = () => {
      if (!ref.current) return;
      setRect(ref.current.getBoundingClientRect());
    };

    measure();

    const observer = new ResizeObserver(debounce(measure, 50));

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return [rect, ref];
}
