import { getHelperText } from "ds_legacy/components/Validation";
import {
  FormElementProps,
  FormElementRenderProps,
  isValid,
} from "react-forms-state/src";
import { useTranslations } from "translations";
import { PasswordInput, PasswordInputProps } from "./password-input";

export function ConnectedPasswordInput({
  debug,
  elementName,
  flatModel,
  onChange,
  sideMutation,
  validation,
  value,
  ...inputProps
}: FormElementProps & PasswordInputProps) {
  const translations = useTranslations();

  return (
    <FormElementRenderProps
      debug={debug}
      elementName={elementName}
      flatModel={flatModel}
      onChange={onChange}
      sideMutation={sideMutation}
      validation={validation}
      value={value}
    >
      {({ onChange, validation, value }) => {
        return (
          <PasswordInput
            {...inputProps}
            errorMessage={
              getHelperText({
                hasCustomValidation: true,
                translations,
                validateOverride: undefined,
                validation,
              }) || inputProps.errorMessage
            }
            isInvalid={!isValid(validation) || inputProps.isInvalid}
            name={elementName}
            onValueChange={(value) => onChange(value)}
            value={value ?? ""}
          />
        );
      }}
    </FormElementRenderProps>
  );
}
