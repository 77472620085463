import { useEnvContext } from "context/EnvContext";
import { activateEnvSwitch } from "core/model/utils/featureFlags";
import { getQueryVariable } from "core/model/utils/urls";
import { Env } from "core/types";
import { useEffect } from "react";

export default function useEnvironmentSwitcher() {
  const envParam = getQueryVariable(location.search, "env") as Env | undefined;
  const { env, setEnv } = useEnvContext();

  useEffect(() => {
    if (activateEnvSwitch && envParam && env !== envParam) {
      setEnv(envParam);
    }
  }, [envParam]);
}
