import Chip from "@mui/material/Chip";
import { styled as muiStyled } from "@mui/system";
import { useEnvContext } from "context/EnvContext";
import { APP_KIND } from "core/consts";
import { LOGIN_SUGGESTIONS } from "core/model/config/loginSuggestions";
import { AppType, Env } from "core/types";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import { Badge, Link } from "ds_legacy/materials/typography";
import styled from "styled-components";

const StyledAppList = styled(HorizontalLayout)`
  justify-content: flex-end;
  & > * + * {
    margin-left: ${margin(0.5)};
    &:before {
      content: " | ";
    }
  }
`;

const APP_URLS: Map<
  AppType,
  {
    href: string;
    label: string;
  }
> = new Map([
  [APP_KIND.APP_CLINIC, { label: "Careseeker", href: "/" }],
  [APP_KIND.APP_CAREPROVIDER, { label: "Careprovider", href: "/careprovider" }],
  [APP_KIND.APP_ACP, { label: "ACP", href: "/acp" }],
  [APP_KIND.APP_B2C, { label: "B2C", href: "/b2c" }],
  [
    APP_KIND.APP_PROVIDER_SEARCH,
    { label: "Provider Search", href: "/providersearch" },
  ],
]);

export function setCurrentAppOptions({ currentApp }: { currentApp: AppType }) {
  const appOptions = new Map(APP_URLS);
  appOptions.delete(currentApp);
  return Array.from(appOptions, ([, value]) => ({ ...value }));
}

const StyledChip = muiStyled(Chip)({
  "&.MuiChip-root": {
    height: dp(20),
    margin: margin(0.5, 0, 0.5, 1),
    color: "black",
  },

  "&.MuiChip-label": {
    padding: padding(0, 1),
  },
});

export default function LoginSuggestions({
  currentEnv,
  onLogin,
  setEmail,
  setPassword,
}: {
  currentEnv: Env;
  onLogin: () => void;
  setEmail: (email: string) => void;
  setPassword: (pass: string) => void;
}) {
  const { app: currentApp } = useEnvContext();
  const appOptions = setCurrentAppOptions({ currentApp });

  const host = window.location.host;
  if (!host.includes("localhost")) return null;

  return (
    <div style={{ margin: margin(0, 1, -2) }}>
      {LOGIN_SUGGESTIONS.map(({ app, email, env, label, password }) => {
        return env.includes(currentEnv) && currentApp === app ? (
          <StyledChip
            key={email}
            variant="outlined"
            color="primary"
            label={label}
            onClick={() => {
              setEmail(email);
              setPassword(password);
              setTimeout(() => onLogin(), 0);
            }}
          />
        ) : null;
      })}

      <StyledAppList>
        {appOptions.map(({ href, label }, index) => (
          <Badge key={index}>
            <Link href={href}>{label}</Link>
          </Badge>
        ))}
      </StyledAppList>
    </div>
  );
}
