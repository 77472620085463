export const LOCAL_STORAGE_KEYS = {
  TRACKING_SESSION: "trackingSession",
  VERSION_CHECKER: "versionChecker",
  CHALLENGE_DATETIME: "challenge_datetime",
} as const;

export function getObjectLocalStorage<T extends AnyObject>(
  key: string,
): T | undefined {
  try {
    const data = localStorage.getItem(key);

    if (!data) return undefined;

    return JSON.parse(data) as T;
  } catch (err) {
    console.error(err);
  }
}

export function setObjectLocalStorage<T extends AnyObject>(
  key: string,
  value: T,
) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getObjectOrDefaultLocalStorage<T extends AnyObject>(
  key: string,
  defaultValue: T,
): T {
  let storedValue = localStorage.getItem(key);

  if (!storedValue) {
    console.info(
      `No value for key '${key}' from localStorage. Setting default value`,
    );
    storedValue = JSON.stringify(defaultValue);
    localStorage.setItem(key, storedValue);
  } else {
    try {
      return JSON.parse(storedValue) as T;
    } catch (error) {
      console.error(`Error parsing value for key '${key}' from localStorage.`);
    }
  }

  return defaultValue;
}

function setWithExpiry<T>(key: string, value: T, expiry: number) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expiry, // Set expiry time
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry<T>(key: string): T | null {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    // If expired, remove from storage and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const localStorageWithExpiry = {
  setItem: setWithExpiry,
  getItem: getWithExpiry,
};
