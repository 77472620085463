import { Button } from "ds/ui";
import { PasswordIndicator } from "ds/ui/input/password-indicator";
import { kebabCase } from "lodash";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { forwardRef, useState } from "react";
import { useTranslations } from "translations";
import { Input, InputProps } from "./input";

export type PasswordInputProps = Omit<
  InputProps,
  "endContent" | "startContent" | "type"
> & {
  type?: "password" | "hidden";
  withIndicator?: boolean;
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    const translations = useTranslations();
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    return (
      <>
        <Input
          ref={ref}
          {...props}
          endContent={
            <Button
              aria-label={
                isVisible
                  ? translations.login.loginPage.hidePassword
                  : translations.login.loginPage.showPassword
              }
              className="focus:outline-none"
              id={
                isVisible
                  ? `hide-password-${kebabCase(props.name)}`
                  : `show-password-${kebabCase(props.name)}`
              }
              isIconOnly
              onPress={toggleVisibility}
              size="sm"
              type="button"
              variant="light"
            >
              {isVisible ? (
                <EyeIcon
                  className="text-md pointer-events-none text-default-900"
                  size={20}
                />
              ) : (
                <EyeOffIcon
                  className="text-md pointer-events-none text-default-900 group-data-[disabled=true]:text-default-disabled"
                  size={20}
                />
              )}
            </Button>
          }
          type={
            props.type === "hidden" ? "hidden" : isVisible ? "text" : "password"
          }
        />
        {props.withIndicator ? (
          <PasswordIndicator password={props.value ?? ""} />
        ) : null}
      </>
    );
  },
);

PasswordInput.displayName = "RecareUI.PasswordInput";
