import { Image } from "@nextui-org/react";
import { FocusScope } from "@react-aria/focus";
import clsx from "clsx";
import { APP_CLINIC, DOCUMENT_PRIVACY_POLICY } from "core/consts";
import {
  ENV_DEMO,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
  getStaticAsset,
  isAcpApp,
  isDemoEnv,
  isPreprodEnv,
  isProdEnv,
  isReceiverApp,
  isSenderApp,
} from "core/model/config";
import { LOGIN_SUGGESTIONS } from "core/model/config/loginSuggestions";
import { capitaliseFirst } from "core/model/utils/strings";
import { Env } from "core/types";

import {
  Banner,
  Button,
  ConnectedInput,
  ConnectedPasswordInput,
  Link,
  Spinner,
} from "ds/ui";
import { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import { getRecareLogoWithNamePath } from "ds_legacy/components/RecareLogo";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { ArrowLeftIcon, LockIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "translations";
import { setCurrentAppOptions } from "../CareproviderOnboardingLoginPage/LoginSuggestions";
import {
  LoginErrorState,
  LoginView,
} from "../CareproviderOnboardingLoginPage/useHandleLogin";
import { loginPage, LoginPageVariants } from "./classes";

export type LoginPageV2Props = LoginPageVariants & {
  challengeTimestamp: string | undefined;
  contactUsUrl?: string;
  env: Env;
  goToForgotPassword: string;
  goToProductionUrl: string;
  handleLogin: (props: {
    challenge?: string;
    email: string;
    password: string;
    token?: string;
  }) => Promise<void>;
  isEmailInputLoading?: boolean;
  isLoading: boolean;
  isSingleSignOn?: boolean;
  loginErrors?: LoginErrorState;
  loginView: LoginView;
  onBackToLogin: () => void;
  onChangeEmail?: (value: string) => void;
  onChangeFormValues?: (value: any, statePath: string, validation: any) => void;
  onSubmit: () => void;
  otherAppUrl?: string;
  resetError: () => void;
  withDevTools?: boolean;
};

const ENVIRONMENTS = [
  ENV_STAGING,
  ENV_PREPROD,
  ENV_DEMO,
  ENV_PRODUCTION,
] as const;

export function LoginPageV2({
  app = APP_CLINIC,
  challengeTimestamp,
  contactUsUrl,
  env,
  goToForgotPassword,
  goToProductionUrl,
  handleLogin,
  isLoading,
  isEmailInputLoading,
  isSingleSignOn,
  loginErrors,
  loginView = "login",
  onBackToLogin,
  onChangeEmail,
  onChangeFormValues,
  onSubmit,
  otherAppUrl,
  resetError,
  withDevTools,
}: LoginPageV2Props) {
  const isDemo = isDemoEnv(env);
  const isProd = isProdEnv(env);
  const isPreprod = isPreprodEnv(env);
  const classes = loginPage({ app, env });
  const translations = useTranslations();
  const { getDocumentUrl } = useLegalDocuments();
  const externalLinkLabel = useExternalLinkLabel();
  const navigate = useNavigate();
  const appOptions = setCurrentAppOptions({ currentApp: app });
  const isLogin = loginView === "login";
  const isChallenge = loginView === "challenge";

  return (
    <div className={classes.page()}>
      {withDevTools && (
        <div className={classes.devToolContainer()}>
          <div
            className={clsx(
              classes.envSwitcher(),
              "rounded-md border-1 border-primary bg-white p-2",
            )}
          >
            {ENVIRONMENTS.map((envOption) => (
              <Button
                color="primary"
                disabled={isLoading}
                id={`env_switch_${envOption}`}
                key={envOption}
                onPress={() => navigate({ search: `?env=${envOption}` })}
                size="sm"
                variant={env === envOption ? "solid" : "light"}
              >
                {capitaliseFirst(envOption)}
              </Button>
            ))}
          </div>
          <div className={classes.loginSuggestion()}>
            {LOGIN_SUGGESTIONS.map((suggestion, i) => {
              return suggestion.env.includes(env) && suggestion.app === app ? (
                <Button
                  color="primary"
                  disabled={isLoading}
                  id={`login_suggestion_${suggestion.label}`}
                  key={i}
                  onPress={() => {
                    handleLogin({
                      email: suggestion.email,
                      password: suggestion.password,
                    });
                    onChangeFormValues?.(suggestion.email, "email", true);
                    onChangeFormValues?.(suggestion.password, "password", true);
                  }}
                  size="sm"
                  variant="ghost"
                >
                  {suggestion.label}
                </Button>
              ) : null;
            })}
          </div>
          <div className={classes.appSuggestion()}>
            {appOptions.map(({ href, label }, index) => (
              <Link
                id={label}
                key={index}
                href={href}
                color="primary-dark"
                size="sm"
              >
                {label}
              </Link>
            ))}
          </div>
        </div>
      )}
      <div className={classes.container()}>
        <div className={classes.content()}>
          <div className={classes.header()}>
            <Image
              width={160}
              alt="Recare logo"
              src={getStaticAsset(getRecareLogoWithNamePath("master"))}
            />
          </div>
          <div className={classes.form()}>
            <div className={classes.formContent()}>
              {isChallenge && (
                <div>
                  <FocusScope autoFocus>
                    <Button
                      color="primary"
                      id="back_to_login"
                      onPress={onBackToLogin}
                      size="sm"
                      startContent={<ArrowLeftIcon size={16} />}
                      variant="light"
                    >
                      {translations.actions.back}
                    </Button>
                  </FocusScope>
                </div>
              )}
              <h1 className={classes.title()}>
                {(isPreprod || isProd) && isAcpApp(app) && (
                  <span className="font-normal">
                    {isProd
                      ? translations.acp.login.prefixProd
                      : translations.acp.login.prefixPreprod}{" "}
                    |{" "}
                  </span>
                )}
                {isDemo && (
                  <span className="font-normal">
                    {translations.login.loginPage.titleDemo} |{" "}
                  </span>
                )}
                {isLogin
                  ? isSenderApp(app)
                    ? translations.login.loginPage.titleSender
                    : isReceiverApp(app)
                    ? translations.login.loginPage.titleReceiver
                    : translations.login.loginPage.titleAcp
                  : translations.login.loginPage.titleAccountVerification}
              </h1>
              {isChallenge && (
                <p className="text-sm">
                  {translations.login.loginPage.accountVerificationInfo}
                </p>
              )}
              {isDemo && (
                <Banner
                  color="warning"
                  size="sm"
                  message={
                    <p>
                      {translations.login.loginPage.environmentInfo}{" "}
                      <Link
                        id="go-to-prod"
                        aria-label={externalLinkLabel(
                          translations.login.loginPage.productionLink,
                          "website",
                        )}
                        color="primary-dark"
                        href={goToProductionUrl}
                        size="sm"
                      >
                        {translations.login.loginPage.productionLink}
                      </Link>
                      .
                    </p>
                  }
                />
              )}
            </div>
            <div className={classes.formContent()}>
              {loginErrors?.general && (
                <Banner
                  role="alert"
                  color="danger"
                  data-testid="login-error"
                  message={loginErrors.general}
                />
              )}
              {isChallenge && challengeTimestamp && (
                <p className="text-sm">
                  {translations.login.challengeScreen.infoTextTimestamp({
                    datetimestring: challengeTimestamp,
                  })}
                </p>
              )}
              <ConnectedInput
                elementName="email"
                errorMessage={loginErrors?.email}
                isInvalid={!!loginErrors?.email}
                label={translations.login.loginPage.email}
                placeholder={translations.login.loginPage.emailPlaceholder}
                required
                sideMutation={() => resetError()}
                type={isLogin ? "email" : "hidden"}
                onValueChange={onChangeEmail}
                endContent={
                  isEmailInputLoading ? <Spinner size="sm" /> : undefined
                }
              />
              {isSingleSignOn && (
                <div
                  role="alert"
                  aria-live="polite"
                  className="flex max-w-fit items-center space-x-2 rounded-md border-1 border-success bg-success-light py-1.5 pl-3 pr-5 text-success-dark"
                >
                  <LockIcon aria-hidden="true" size={14} />{" "}
                  <p className="text-sm">
                    {translations.login.loginPage.ssoInfo}
                  </p>
                </div>
              )}
              <ConnectedPasswordInput
                elementName="password"
                errorMessage={loginErrors?.password}
                isInvalid={!!loginErrors?.password}
                label={translations.login.loginPage.password}
                placeholder={translations.login.loginPage.passwordPlaceholder}
                required
                sideMutation={() => resetError()}
                type={isLogin && !isSingleSignOn ? "password" : "hidden"}
              />
              <ConnectedInput
                elementName="challenge"
                errorMessage={loginErrors?.challenge}
                isInvalid={!!loginErrors?.challenge}
                label={translations.login.loginPage.verificationCode}
                placeholder={
                  translations.login.loginPage.verificationCodePlaceholder
                }
                required
                sideMutation={() => resetError()}
                type={isLogin ? "hidden" : "text"}
              />
              {isLogin && !isSingleSignOn && (
                <p>
                  <Link
                    color="primary-dark"
                    href={goToForgotPassword}
                    size="sm"
                    id="forgot-password"
                  >
                    {translations.login.loginPage.forgotPassword}
                  </Link>
                </p>
              )}
            </div>
            <div className="flex justify-between">
              <Button
                color="primary"
                fullWidth
                id={
                  isLogin
                    ? isSingleSignOn
                      ? "login_sso"
                      : "login"
                    : "login_challenge"
                }
                isLoading={isLoading}
                onPress={onSubmit}
                size="lg"
                type="submit"
                variant="solid"
              >
                {translations.login.loginPage.loginButton}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.footer()}>
          {isLogin && !isAcpApp(app) && (
            <div className={classes.footerLinks()}>
              <p>
                {translations.login.loginPage.contactInfo}{" "}
                <Link
                  id="contact-us"
                  aria-label={externalLinkLabel(
                    translations.login.loginPage.contactInfoLink,
                    "website",
                  )}
                  color="primary-dark"
                  href={contactUsUrl}
                  size="sm"
                >
                  {translations.login.loginPage.contactInfoLink}
                </Link>
              </p>
              <p>
                {isSenderApp(app)
                  ? translations.login.loginPage.receiverInfo
                  : translations.login.loginPage.clinicInfo}{" "}
                <Link
                  id="switch-app"
                  aria-label={externalLinkLabel(
                    translations.login.clinic.clickHere,
                    "website",
                  )}
                  color="primary-dark"
                  href={otherAppUrl}
                  size="sm"
                >
                  {isSenderApp(app)
                    ? translations.login.loginPage.receiverInfoLink
                    : translations.login.loginPage.clinicLink}
                </Link>
              </p>
            </div>
          )}
          <hr className={classes.footerDivider()} />
          <p>
            {translations.login.loginPage.privacyPolicyInfo}{" "}
            <Link
              id="privacy-policy"
              aria-label={externalLinkLabel(
                translations.login.privacyPolicy,
                "pdf",
              )}
              href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
              size="sm"
              target="_blank"
              color="primary-dark"
            >
              {translations.login.privacyPolicy}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
